<script lang="ts" setup>
  import type { UnwrapRef } from 'vue';
  import { computed, reactive, ref } from 'vue';
  import dayjs, { Dayjs } from 'dayjs';

  import { useSiderBarStore } from '../../store/sidebar';
  import { useQuote } from '@/quotes/composables/useQuote';
  import IconClear from '@/quotes/components/icons/IconClear.vue';
  import IconSearch from '@/quotes/components/icons/IconSearch.vue';

  import type { DestinationsCountry, DestinationsState } from '@/quotes/interfaces';
  import { useQuoteServices } from '@/quotes/composables/useQuoteServices';
  import type { ServicesSubType } from '@/quotes/interfaces/services';
  import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';
  import { useFilesStore } from '@/stores/files';

  import { useI18n } from 'vue-i18n';
  import { isArray, isObject } from 'lodash';

  const { t } = useI18n();
  const { getLang } = useQuoteTranslations();

  // store
  // TODO: refactor to use composable instead
  const storeSidebar = useSiderBarStore();

  // Composable
  const { quote, deleteServiceSelected } = useQuote();
  const { serviceTypeMeals, getMealsAvailable, getCountryStates } = useQuoteServices();

  // const countries = computed(() => servicesDestinations.value!.destinationsCountries);
  const destinationsStates = computed(() => getCountryStates(searchFormState.country.code));
  const typeMeals = computed(() => serviceTypeMeals.value);

  // const default_value = ref<number>(70);

  const priceRange = ref<Record<number, number>>({
    0: 0,
    150: 150,
    500: 500,
    950: 950,
  });
  const maxRange = 1000;
  // Form
  const searchFormRef = ref();

  const filesStore = useFilesStore();

  const props = defineProps({
    isFile: { type: Boolean, default: false },
  });

  interface SearchForm {
    date_from: Dayjs | undefined;
    country: DestinationsCountry;
    state: DestinationsState | null;
    service_sub_category: ServicesSubType | null;
    service_name: string;
    priceRange: { min: number; max: number };
    passengers: [];
  }

  const searchFormState: UnwrapRef<SearchForm> = reactive({
    date_from: dayjs(quote.value.date_in),
    country: {
      code: '89',
      label: 'Perú',
    },
    state: null,
    service_sub_category: null,
    service_name: '',
    priceRange: {
      min: 0,
      max: 850,
    },
    passengers: [],
  });

  // const searchFormRules: Record<string, Rule[]> = {
  //   date_from: [
  //     {
  //       required: true,
  //       message: t('quote.label.select_start_day'),
  //       trigger: 'change',
  //       type: 'object',
  //     },
  //   ],
  //   country: [
  //     {
  //       required: true,
  //       message: t('quote.label.select_country'),
  //       trigger: 'change',
  //     },
  //   ],
  //   state: [
  //     {
  //       required: true,
  //       message: t('quote.label.select_city'),
  //       trigger: 'change',
  //     },
  //   ],
  // };

  const dateFormat = 'DD/MM/YYYY';

  // const countryChange = (value: { option: DestinationsCountry }) => {
  //   searchFormState.country = value.option;
  //   searchFormState.state = null;
  // };
  const stateChange = (value: { option: DestinationsState }) => {
    searchFormState.state = value.option;
  };
  const typeMealChange = (value: { option: ServicesSubType }) => {
    searchFormState.service_sub_category = value.option;
  };
  const search = () => {
    storeSidebar.setStatus(false, '', '');
    deleteServiceSelected();

    const destiny = {
      code: searchFormState.country.code + ',' + (searchFormState.state?.code ?? ''),
      label: searchFormState.country.label + ',' + (searchFormState.state?.label ?? ''),
    };

    searchFormRef.value
      .validate()
      .then(async () => {
        if (props.isFile) {
          filesStore.initedAsync();
          let adults_ = filesStore.getQuantityAdults(searchFormState.passengers);
          let children_ = filesStore.getQuantityChildren(searchFormState.passengers);
          filesStore.putSearchPassengers(searchFormState.passengers);

          let categories =
            searchFormState.service_sub_category?.id ?? typeMeals.value.map((meal) => meal.id);

          if (!(isObject(categories) || isArray(categories))) {
            categories = [categories];
          }

          await filesStore.fetchServices({
            quantity_persons: {
              adults: adults_,
              child: children_,
              age_childs: [
                {
                  age: 1,
                },
              ],
            },
            date: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            client_id: localStorage.getItem('client_id'),
            origin: destiny,
            filter: searchFormState.service_name,
            type: 'all',
            experience: 'all',
            classification: 'all',
            category: categories,
            limit: 10,
            page: 1,
          });
          filesStore.finished();
          console.log('Services: ', filesStore.getServices);
        } else {
          await getMealsAvailable({
            adults: quote.value.people[0].adults | 1,
            allWords: 1, // true
            children: quote.value.people[0].child,
            date_from: searchFormState.date_from!.format('YYYY-MM-DD'),
            destiny: '', //destiny
            lang: getLang(),
            origin: destiny,
            service_name: searchFormState.service_name,
            service_sub_category: searchFormState.service_sub_category?.id ?? '',
            price_range: searchFormState.priceRange,
          });

          storeSidebar.setStatus(true, 'service', 'search');
        }
      })
      .catch((error: string) => {
        console.log('error', error);
      });
  };
  const clearForm = () => {
    searchFormRef.value.resetFields();
  };
</script>

<template>
  <a-form id="tours" class="container" ref="searchFormRef" :model="searchFormState">
    <div id="meals" class="container">
      <div class="row-box">
        <div class="input-box">
          <label for="start-date">{{ t('quote.label.date') }}: *</label>
          <a-form-item
            name="date_from"
            :rules="{
              required: true,
              message: t('quote.label.select_start_day'),
            }"
          >
            <a-date-picker
              v-model:value="searchFormState.date_from"
              id="start-date"
              :format="dateFormat"
            />
          </a-form-item>
        </div>
        <div class="input-box">
          <label for="country">{{ t('quote.label.city') }}: *</label>
          <a-form-item
            name="state"
            :rules="{
              required: searchFormState.service_name == '',
              message: t('quote.label.select_city'),
            }"
          >
            <a-select
              showSearch
              optionFilterProp="label"
              v-model:value="searchFormState.state"
              :options="destinationsStates"
              :field-names="{ label: 'label', value: 'code' }"
              label-in-value
              @change="stateChange"
            ></a-select>
          </a-form-item>
        </div>
        <div class="input-box">
          <label for="country">{{ t('quote.label.kind_of_food') }}: </label>
          <a-form-item name="type_meal">
            <a-select
              v-model:value="searchFormState.service_sub_category"
              :options="typeMeals"
              :field-names="{ label: 'label', value: 'id' }"
              label-in-value
              :allow-clear="true"
              @change="typeMealChange"
            ></a-select>
          </a-form-item>
        </div>
        <div class="input-box" v-if="props.isFile">
          <label for="passengers" class="d-block mb-1"
            >{{ t('global.label.passengers') }} <b class="text-danger">*</b></label
          >
          <a-form-item
            :rules="{
              required: true,
              message: t('files.message.select_passengers'),
            }"
          >
            <a-select
              mode="tags"
              id="passengers"
              v-model:value="searchFormState.passengers"
              :fieldNames="{ label: 'label', value: 'id' }"
              style="width: 180px"
              max-tag-count="responsive"
              :options="filesStore.getFilePassengers"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="input-box">
          <label for="start-date">{{ t('quote.label.price_range') }}: *</label>
          <a-slider
            v-model:value="searchFormState.priceRange.max"
            :marks="priceRange"
            :max="maxRange"
          />
        </div>
      </div>
      <div class="row-box">
        <div class="input-box search meals">
          <label for="country">{{ t('quote.label.filter_by_words') }}: </label>
          <a-form-item name="service_name" class="mb-0 w-100">
            <a-input
              v-model:value="searchFormState.service_name"
              :placeholder="t('quote.label.write_here')"
            />
          </a-form-item>
        </div>
        <div class="actions_buttons">
          <div class="text" @click="clearForm">
            <icon-clear />
            <span>{{ t('quote.label.clean_filters') }}</span>
          </div>
          <div class="search_button_container" @click="search">
            <div class="search-button">
              <div class="content">
                <div class="icon">
                  <icon-search />
                </div>
                <div class="text">{{ t('quote.label.search') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>

<style lang="scss" scoped>
  form#tours.container {
    .input-box.search {
      .ant-row.ant-form-item {
        width: 33.5% !important;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    form#tours.container {
      .input-box.search {
        .ant-row.ant-form-item {
          width: 35.5% !important;
        }
      }
    }
  }
</style>
