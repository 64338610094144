<template>
  <a-row
    type="flex"
    align="middle"
    justify="space-between"
    v-bind:class="['files-edit__header', !isEditable() ? 'mb-3' : '']"
  >
    <a-col>
      <div class="files-edit__fileinfo">
        <div class="files-edit__fileinfo-left">
          <files-popover-info :data="filesStore.getFile" />
          <span class="files-edit__filelabel">File N°</span>
          <span class="files-edit__filenum"> {{ filesStore.getFile.fileNumber }} </span>
        </div>

        <div class="custom-box" v-if="filesStore.getFile.status == 'xl'">
          Motivo de anulación:
          <strong>{{ filesStore.getFile.statusReason.replace('Anulado - ', '') }}</strong>
        </div>

        <div class="files-edit__fileinfo-center">
          <span class="files-edit__filealert" v-show="filesStore.getFile.fileNumber == 0">
            <a-alert type="warning" closable>
              <template #message>
                <WarningOutlined :style="{ fontSize: '18px', color: '#FFCC00' }" /> Para crear el
                file y el sistema te asigne un número de file, debes agregar un servicio.
              </template>
            </a-alert>
          </span>
        </div>
        <div class="files-edit__fileinfo-right" v-if="!editable">
          <div class="files-edit__statuses">
            <template v-if="!editable">
              <base-popover placement="bottom">
                <base-badge
                  v-if="filesStore.getFile?.status"
                  :type="statusByIso(filesStore.getFile.status).type"
                >
                  {{ statusByIso(filesStore.getFile.status).name }}
                </base-badge>
                <template #content>
                  <div class="box-description">{{ filesStore.getFile.statusReason }}</div>
                </template>
              </base-popover>

              <base-badge :type="filesStore.getFile.haveInvoice ? 'billed' : 'unbilled'">
                {{ haveInvoiceByIso(filesStore.getFile.haveInvoice).name }}
              </base-badge>

              <base-badge
                :type="
                  getRevisionStageById(filesStore.getFile.revisionStages).type +
                  '-' +
                  filesStore.getFile.opeAssignStages
                "
              >
                {{ getRevisionStageById(filesStore.getFile.revisionStages).name }}
              </base-badge>
            </template>
            <template v-else>
              <template v-if="filesStore.getFile.status == 'xl'">
                <a-alert type="error">
                  <template #message>
                    <div class="text-dark">
                      Motivo de anulación: <b>Situación política / social en país destino Perú</b>
                    </div>
                  </template>
                </a-alert>
              </template>
            </template>
          </div>
        </div>
      </div>
    </a-col>
    <a-col flex="auto">
      <div class="text-right px-2 in-charge">
        <p class="mb-0"><small>EC ENCARGADA</small></p>
        <p class="mb-0">
          <b>{{ executiveFile.toUpperCase() }}</b>
        </p>
      </div>
    </a-col>
    <a-col>
      <div class="files-edit__avatars" v-if="editable && filesStore.getFile.fileNumber !== 0">
        <files-popover-avatar label="Administrador" />
        <files-popover-avatar label="Invitado" />
        <files-popover-avatar label="Otro usuario" />
      </div>
    </a-col>
  </a-row>
  <div class="files-edit__subheader" v-if="editable">
    <div class="files-edit__subheader-col1" v-if="filesStore.getFile.fileNumber !== 0">
      <div class="files-edit__statuses text-uppercase">
        <base-popover placement="bottom">
          <base-badge
            v-if="filesStore.getFile?.status"
            :type="statusByIso(filesStore.getFile.status).type"
          >
            {{ statusByIso(filesStore.getFile.status).name }}
          </base-badge>
          <template #content>
            <div class="box-description">{{ filesStore.getFile.statusReason }}</div>
          </template>
        </base-popover>

        <base-badge :type="filesStore.getFile.haveInvoice ? 'billed' : 'unbilled'">
          {{ haveInvoiceByIso(filesStore.getFile.haveInvoice).name }}
        </base-badge>

        <base-badge
          :type="
            getRevisionStageById(filesStore.getFile.revisionStages).type +
            '-' +
            filesStore.getFile.opeAssignStages
          "
        >
          {{ getRevisionStageById(filesStore.getFile.revisionStages).name }}
        </base-badge>
      </div>
      <files-switch-serie
        :class="{ 'block-style': isEditable() }"
        :isEditable="isEditable()"
        label-unchecked="Asociar a serie"
        label-checked="Escoger serie"
        v-if="filesStore.getFile.fileNumber !== 0"
      />
    </div>
    <div class="files-edit__subheader-col2">
      <template v-if="filesStore.getFile.fileNumber !== 0">
        <a-dropdown class="except-block-style">
          <a-button
            style="height: auto"
            class="btn-default text-600 except-block-style"
            size="large"
            @click.prevent
            overlayClassName="btn-create-file-item"
            :disabled="filesStore.isLoading"
            >{{ t('files.button.new') }}
          </a-button>
          <template #overlay>
            <a-menu class="file-dropdown-menu">
              <a-menu-item key="1" class="file-menu-item" @click="showModalCreateFile">
                <div class="menu-item-content">
                  <IconDocPlus color="#EB5757" width="1.5em" height="1.5em" />
                  <span class="menu-text">{{ t('global.label.from') }} 0</span>
                </div>
              </a-menu-item>
              <a-menu-item key="2" class="file-menu-item" @click="showModalCloneFile">
                <div class="menu-item-content">
                  <IconCloneSolid color="#EB5757" width="1.2em" height="1.2em" />
                  <span class="menu-text">{{ t('global.label.clone') }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-button
          class="ms-3 text-600"
          type="primary"
          ghost
          @click="handleCancel"
          size="large"
          v-if="filesStore.getFile.status == 'ok'"
        >
          {{ t('global.button.destroy') }}
        </a-button>
      </template>
      <template v-if="filesStore.getFile.status == 'xl'">
        <a-button
          type="primary"
          default
          @click="showModalActivate"
          size="large"
          class="ms-3 text-600"
        >
          {{ t('global.button.activate') }}
        </a-button>
      </template>
    </div>
  </div>
  <div class="position-relative" :class="{ 'block-style': !isEditable() }">
    <div class="locked" v-if="filesStore.getFile.status == 'xl'"></div>
    <div class="files-edit__information">
      <div class="files-edit__wrap">
        <files-popover-vip
          v-if="filesStore.getFile.id > 0"
          :editable="isEditable()"
          :data="filesStore.getFile"
          @onRefreshCache="handleRefreshCache"
        />
        <files-toggler-file-name :data="filesStore.getFile.description" :editable="isEditable()" />
      </div>

      <files-toggler-start-day :editable="isEditable()">
        <span style="text-transform: capitalize">
          {{ formatDate(filesStore.getFile.dateIn, 'MMM DD, YYYY') }}
        </span>
      </files-toggler-start-day>

      <files-edit-field-static :editable="isEditable()" :hide-content="true">
        <template #label>Día de finalización</template>
        <template #content>
          <span style="text-transform: capitalize" v-if="filesStore.getFile.fileNumber !== 0">
            {{ formatDate(filesStore.getFile.dateOut, 'MMM DD, YYYY') }}
          </span>
          <span v-else>&nbsp;</span>
        </template>
      </files-edit-field-static>

      <files-edit-field-static :editable="isEditable()" :hide-content="false">
        <template #label>{{ t('global.label.client_name') }}</template>
        <template #content>
          {{ filesStore.getFile.clientCode }}
        </template>
        <template #popover-content>
          <div class="files-edit-field-static-wrap">
            <div class="d-flex" style="gap: 5px">
              <strong>MKP:</strong>
              <span style="font-weight: normal">{{ t('global.label.client') }}</span>
              <span class="lt-red">{{ filesStore.getFile.markupClient }}{{ '%' }}</span>
            </div>
            <div class="d-flex" style="gap: 5px">
              <strong>{{ t('global.label.name') }}:</strong>
              <span class="lt-red"
                >({{ filesStore.getFile.clientCode }}) {{ filesStore.getFile.clientName }}</span
              >
            </div>
          </div>
        </template>
      </files-edit-field-static>

      <files-edit-field-static :editable="isEditable()" :hide-content="false">
        <template #label>{{ t('global.label.passengers') }}</template>
        <template #content>
          <div style="display: flex; gap: 3px; cursor: pointer" @click="goToPaxs">
            <font-awesome-icon style="width: 15.31px; height: 17.5px" icon="fa-solid fa-user" />
            {{
              filesStore.getFile?.adults ? String(filesStore.getFile.adults).padStart(2, '0') : '00'
            }}
            <font-awesome-icon
              style="width: 13.75px; height: 18.33px"
              icon="fa-solid fa-child-reaching"
              v-if="filesStore.getFile.children > 0"
            />
            {{
              filesStore.getFile?.children
                ? String(filesStore.getFile.children).padStart(2, '0')
                : ''
            }}
            <font-awesome-icon
              style="width: 20px; height: 20px"
              icon="fa-solid fa-baby-carriage"
              v-if="filesStore.getFile.infants > 0"
            />
            {{
              filesStore.getFile?.infants ? String(filesStore.getFile.infants).padStart(2, '0') : ''
            }}
          </div>
        </template>
        <template #popover-content
          >{{ t('global.label.goto') }} {{ t('global.label.passengers') }}
        </template>
      </files-edit-field-static>

      <files-edit-field-static :editable="isEditable()" :hide-content="true">
        <template #label>{{ t('global.label.accommodation') }}</template>
        <template #content>
          <BaseSelectAccommodations v-model="accommodation" :box="false" />
        </template>
      </files-edit-field-static>

      <files-toggler-language :isEditable="isEditable()" />

      <files-edit-field-static
        :editable="isEditable()"
        :hide-content="false"
        v-if="filesStore.getFile.generateStatement || filesStore.getFile.amountSale > 0"
      >
        <template #label>Statement</template>
        <template #content>
          <div class="files-edit-field-statement" style="gap: 3px" @click="openStatementView">
            <font-awesome-icon
              style="width: 11.25px; height: 18px"
              icon="fa-solid fa-dollar-sign"
            />
            {{
              filesStore.getFile.amountSale
                ? formatNumber({ number: filesStore.getFile.amountSale })
                : '0.000'
            }}
          </div>
        </template>
        <template #popover-content>
          <div class="files-edit-field-static-wrap d-flex" style="gap: 5px">
            <strong>Rentabilidad del File:</strong>
            <span :class="[filesStore.getFile.profitability > 0 ? 'lt-green' : 'lt-red']">
              {{ filesStore.getFile.profitability }}%
            </span>
          </div>
        </template>
      </files-edit-field-static>
    </div>
    <CreateFileModal :is-open="modalIsOpen" @update:is-open="modalIsOpen = $event" />
    <CloneFileModal
      :is-open="modalCloneIsOpen"
      @update:is-open="modalCloneIsOpen = $event"
      :showFileSelect="false"
      :setFileId="''"
    />
  </div>

  <a-modal v-model:visible="modalActivate" title="Activar file" :width="400">
    <a-alert class="text-warning" type="warning" show-icon v-if="checked == 1 || checked == 2">
      <template #icon> </template>
      <template #description>
        <template v-if="checked == 1">
          Para activar el file, debe revisar las comunicaciones que generan hoteles y servicios.
        </template>
        <template v-if="checked == 2">
          Para modificar la fecha y activar el file debe ir a cotizar.
        </template>
      </template>
    </a-alert>
    <p class="text-center mt-3 mx-2 font-490">
      Desea activar el file con:
      <a-radio-group v-model:value="checked">
        <a-radio :value="1" v-bind:disabled="lockedOriginalDate">Fecha original</a-radio>
        <a-radio :value="2">Nueva fecha</a-radio>
      </a-radio-group>
    </p>
    <div
      v-if="checked == 2"
      :style="{ width: '300px', border: '1px solid #d9d9d9', borderRadius: '6px' }"
    >
      <a-calendar
        :disabledDate="disabledDate"
        v-model:value="newDateActivate"
        :fullscreen="false"
        @change="onChange"
      ></a-calendar>
    </div>
    <template #footer>
      <div class="text-center">
        <a-button
          type="default"
          class="bnt-default"
          default
          @click="hideModalActivate"
          size="large"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          primary
          :loading="loading"
          v-if="checked == 1 || checked == 2"
          @click="handleActivate"
          size="large"
        >
          <template v-if="checked == 1"> Continuar </template>
          <template v-if="checked == 2"> Ir a cotizar </template>
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
  import { formatDate, formatNumber } from '@/utils/files.js';
  import { notification } from 'ant-design-vue';
  import dayjs from 'dayjs';

  import FilesPopoverInfo from '@/components/files/edit/FilesPopoverInfo.vue';
  import FilesPopoverAvatar from '@/components/files/edit/FilesPopoverAvatar.vue';
  import FilesPopoverVip from '@/components/files/edit/FilesPopoverVip.vue';
  import FilesEditFieldStatic from '@/components/files/edit/FilesEditFieldStatic.vue';
  import FilesTogglerFileName from '@/components/files/edit/FilesTogglerFileName.vue';
  import FilesTogglerLanguage from '@/components/files/edit/FilesTogglerLanguage.vue';
  import FilesTogglerStartDay from '@/components/files/edit/FilesTogglerStartDay.vue';
  import FilesSwitchSerie from '@/components/files/edit/FilesSwitchSerie.vue';
  import BaseSelectAccommodations from '@/components/files/reusables/BaseSelectAccommodations.vue';
  import BaseBadge from '@/components/files/reusables/BaseBadge.vue';
  import BasePopover from './BasePopover.vue';
  import { WarningOutlined } from '@ant-design/icons-vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import {
    useExecutivesStore,
    useFilesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
    useStatusesStore,
  } from '@store/files';

  import { onBeforeMount, ref } from 'vue';
  import CreateFileModal from '@/components/files/create/CreateFileModal.vue';
  import CloneFileModal from '@/components/files/clone/CloneFileModal.vue';
  import IconDocPlus from '@/components/icons/IconDocPlus.vue';
  import IconCloneSolid from '@/components/icons/IconCloneSolid.vue';

  const { t } = useI18n({
    useScope: 'global',
  });

  const filesStore = useFilesStore();
  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();
  const executivesStore = useExecutivesStore();

  const statusByIso = (iso) => statusesStore.getStatusByIso(iso);
  const haveInvoiceByIso = (iso) => haveInvoicesStore.getHaveInvoiceByIso(iso);
  const getRevisionStageById = (id) => revisionStagesStore.getRevisionStageById(id);

  const emit = defineEmits([
    'onhandleOpenStatementView',
    'onHandleGoToPaxs',
    'onHandleRefreshCache',
  ]);

  const router = useRouter();
  const modalIsOpen = ref(false);
  const modalCloneIsOpen = ref(false);
  const accommodation = ref({});

  const openStatementView = () => {
    emit('onHandleOpenStatementView');
  };

  const props = defineProps({
    data: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: () => true,
    },
  });

  const executiveFile = ref('');
  const modalActivate = ref(false);
  const lockedOriginalDate = ref(false);

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const goToPaxs = () => {
    emit('onHandleGoToPaxs');
  };

  const handleRefreshCache = async () => {
    emit('onHandleRefreshCache');
  };

  const handleCancel = async () => {
    let flag_continue = true;

    filesStore.getFileItineraries.map((itinerary) => {
      if (!itinerary.confirmation_status && flag_continue) {
        notification.error({
          message: 'Error al anular',
          description: `El ${itinerary.name} del día ${itinerary.date_in} no se encuentra confirmado. Por lo que no podemos anular el File.`,
        });

        flag_continue = false;
      }
    });

    if (flag_continue) {
      let route = 'files-cancel';
      let params = {
        id: filesStore.getFile.id,
      };

      router.push({ name: route, params: params });
    }
  };

  const checked = ref(0);
  const newDateActivate = ref();

  const verifyOriginalDate = () => {
    let date1 = dayjs(filesStore.getFile.dateIn);
    let date2 = dayjs(new Date());
    let days = date1.diff(date2, 'day');
    lockedOriginalDate.value = days < 7;
  };

  const hideModalActivate = () => {
    modalActivate.value = false;
  };

  const showModalActivate = () => {
    verifyOriginalDate();
    modalActivate.value = true;
  };

  const handleActivate = async () => {
    modalActivate.value = false;
    filesStore.inited();

    if (checked.value == 2) {
      let params = {
        fileId: filesStore.getFile.id,
        force: true,
        date_init: dayjs(newDateActivate.value).format('YYYY-MM-DD'),
        activate: true,
      };

      await filesStore.sendQuote(params);

      if (filesStore.getFlagSendBoard) {
        localStorage.setItem('a3_file_id', filesStore.getFile.id);
        window.location.href = `${window.url_app}quotes`;
      }
    } else {
      let route = 'files-activate';
      let params = {
        id: filesStore.getFile.id,
      };

      router.push({ name: route, params: params });
    }
  };

  const showModalCreateFile = () => {
    modalIsOpen.value = true;
  };

  const showModalCloneFile = () => {
    modalCloneIsOpen.value = true;
  };

  onBeforeMount(async () => {
    accommodation.value = {
      SGL: filesStore.getFile.suggested_accommodation_sgl,
      DBL: filesStore.getFile.suggested_accommodation_dbl,
      TPL: filesStore.getFile.suggested_accommodation_tpl,
    };

    let code = filesStore.getFile.executiveCode;
    await executivesStore.fetchAll(code);
    executiveFile.value =
      executivesStore.getExecutives[0]?.label || filesStore.getFile.executiveCode;

    setTimeout(async () => {
      let file = filesStore.getFile;
      if (file.executiveName == undefined) {
        let array_codes = filesStore.getFile.executiveCode;
        await executivesStore.fetchAllBoss(array_codes);

        array_codes = array_codes.split(',');
        let boss = executivesStore.getBoss;

        let bossCode = boss.executives_boss[file.executiveCode];

        if (bossCode != undefined) {
          file.bossCode = bossCode;
          array_codes.push(bossCode);
        }

        array_codes = array_codes.join(',');
        await executivesStore.findAll(array_codes);

        file.bossName = executivesStore.getAllExecutives[file.bossCode]?.name;
        file.executiveName = executivesStore.getAllExecutives[file.executiveCode].name;
      }

      console.log('FILE: ', filesStore.getFile);
      console.log('ACCOMMODATIONS: ', accommodation.value);
    }, 350);
  });

  const isEditable = () => {
    if (!filesStore.getFile.statusReason) {
      return;
    }
    let reopen = !filesStore.getFile.statusReason.toLowerCase().includes('reaperturado');
    return props.editable && filesStore.getFile.status === 'ok' && reopen;
  };
</script>
<style scoped lang="scss">
  .file-dropdown-menu {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 4px 0;

    :deep(.ant-dropdown-menu-item) {
      padding: 10px; /* Ajusta el padding de los items */
      .menu-item-content {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          color: #eb5757;
          margin-left: 5px;
        }

        /* Estilos para el texto del item */
        span {
          font-family: 'Montserrat', sans-serif;
          font-weight: 500;
          font-size: 16px;
          color: #eb5757;
          margin-right: 15px;
        }
      }

      /* Ajustes cuando un item está activo (hover o selección) */
      &:hover {
        background-color: #ffffff; /* Color de fondo del ítem cuando está activo */
      }

      /* Ajustes para ítems seleccionados */
      &.ant-dropdown-menu-item-selected {
        background-color: #ffffff; /* Color de fondo del ítem seleccionado */
      }
    }
  }

  .block-style * {
    opacity: 0.85;
  }

  /* Excluye los elementos con la clase 'except-block-style' */
  .block-style .except-block-style {
    opacity: 1 !important;
    pointer-events: auto;
    isolation: isolate;
  }

  .custom-box {
    border: 1px solid #f5a89a; /* Borde de color tenue (rosa claro) */
    border-radius: 8px; /* Bordes redondeados */
    padding: 12px 16px; /* Espacio interno */
    background-color: #fafafa; /* Fondo blanco */
    font-size: 14px; /* Tamaño de texto */
    color: #595959; /* Color del texto principal */
  }

  .custom-box strong {
    font-weight: 600; /* Texto en negrita para resaltar */
    color: #262626; /* Color más oscuro para el texto resaltado */
  }
</style>
