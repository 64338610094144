<script lang="ts" setup>
  import { reactive, ref, watch } from 'vue';
  import dayjs, { Dayjs } from 'dayjs';

  import type { UnwrapRef } from 'vue';
  import type { Rule } from 'ant-design-vue/es/form';

  import IconSearch from '@/quotes/components/icons/IconSearch.vue';
  import IconClear from '@/quotes/components/icons/IconClear.vue';
  import { useSiderBarStore } from '../../store/sidebar';
  import useQuoteDestinations from '@/quotes/composables/useQuoteDestinations';
  import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';
  import { useQuoteHotelCategories } from '@/quotes/composables/useQuoteHotelCategories';
  import { useQuoteHotels } from '@/quotes/composables/useQuoteHotels';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();
  const { getLang } = useQuoteTranslations();
  import type {
    QuoteHotelsSearchRequest,
    DestinationsState,
    DestinationsCountry,
    QuoteHotelCategory,
  } from '@/quotes/interfaces';
  import { useQuote } from '@/quotes/composables/useQuote';

  const storeSidebar = useSiderBarStore();
  const priceRange = ref<Record<number, number>>({
    0: 0,
    150: 150,
    500: 500,
    950: 950,
  });
  const maxRange = 1000;

  // destinations
  const { destinations, getStatesByCountryCode } = useQuoteDestinations();
  // const destinationsCountries = ref<DestinationsCountry[]>([])
  const destinationsCountry = ref<DestinationsCountry>({
    code: 'PE',
    label: 'Perú',
  });

  const destinationsStates = ref<DestinationsState[]>([]);
  // const destinationsStatesSelected = ref<DestinationsState[]>([])
  // const handleDestinationsStatesChange = (value: { option:DestinationsState }[]) => {
  // destinationsStatesSelected.value = value.length ? value.map(s => s.option):[]
  // };
  const destinationsStatesSelected = ref<DestinationsState>();
  const handleDestinationsStatesChange = (value: { option: DestinationsState }) => {
    destinationsStatesSelected.value = value.option;
  };

  // watch(destinationsCountry,  ()=> {
  watch(
    destinations,
    () => {
      destinationsStates.value = getStatesByCountryCode(destinationsCountry.value.code);
    },
    { immediate: true }
  );

  // categories
  const { quoteHotelCategories: categories } = useQuoteHotelCategories();
  // const selectedCategories = ref<QuoteHotelCategory[]>([])
  // const handleDestinationsCategoriesChange = (value: { option:QuoteHotelCategory }[]) => {
  // selectedCategories.value = value.length ? value.map(s => s.option):[]
  // };
  const selectedCategories = ref<QuoteHotelCategory>();
  const handleDestinationsCategoriesChange = (value: { option: QuoteHotelCategory }) => {
    selectedCategories.value = value.option;
  };

  // Dates
  const dateFormat = 'DD/MM/YYYY';

  // hotels search
  const { getHotels } = useQuoteHotels();
  const { quote, deleteServiceSelected } = useQuote();

  // form
  const HotelsFormRef = ref();

  interface SearchHotelsForm {
    checkIn: Dayjs | undefined;
    checkOut: Dayjs | undefined;
    // destiny: DestinationsState[]
    // categories: QuoteHotelCategory[]
    destiny: DestinationsState | undefined;
    categories: QuoteHotelCategory | undefined;
    priceRange: { min: number; max: number };
    keywordFilter: string;
  }

  const HotelsFormState: UnwrapRef<SearchHotelsForm> = reactive({
    checkIn: dayjs(quote.value.date_in),
    checkOut: dayjs(quote.value.date_in).add(quote.value.nights, 'day'),
    destiny: undefined,
    categories: undefined,
    // destiny: []
    // categories: []
    priceRange: {
      min: 0,
      max: 850,
    },
    keywordFilter: '',
  });

  const HotelFormRules: Record<string, Rule[]> = {
    checkIn: [
      {
        required: true,
        message: t('quote.label.select_Check_in'),
        trigger: 'change',
        type: 'object',
      },
    ],
    checkOut: [
      {
        required: true,
        message: t('quote.label.select_Check_out'),
        trigger: 'change',
        type: 'object',
      },
    ],
    destiny: [
      {
        required: true,
        message: t('quote.label.select_destination'),
        trigger: 'change',
      },
    ],
  };

  const quoteHotelsSearchRequest: UnwrapRef<QuoteHotelsSearchRequest> = reactive({
    date_from: '',
    date_to: '',
    quantity_persons_rooms: [],
    quantity_rooms: 1,
    set_markup: 0,
    zero_rates: true,
    hotels_search_code: '',
    allWords: false,
    type_classes: ['all'],
    typeclass_id: 'all',
    lang: getLang(),
    price_range: {
      min: 0,
      max: 850,
    },
    destiny: {
      code: '',
      label: '',
    },
  });

  const searchHotels = () => {
    storeSidebar.setStatus(false, 'hotel', 'search');
    deleteServiceSelected();
    HotelsFormRef.value
      .validate()
      .then(async () => {
        quoteHotelsSearchRequest.date_from = HotelsFormState.checkIn?.format('YYYY-MM-DD') ?? '';
        quoteHotelsSearchRequest.date_to = HotelsFormState.checkOut?.format('YYYY-MM-DD') ?? '';

        quoteHotelsSearchRequest.typeclass_id = selectedCategories.value
          ? selectedCategories.value.value
          : 'all';
        // quoteHotelsSearchRequest.type_classes = selectedCategories.value.length ? selectedCategories.value.map((c) => parseInt(c.code)) : ['all']
        quoteHotelsSearchRequest.type_classes = [quoteHotelsSearchRequest.typeclass_id];

        // quoteHotelsSearchRequest.destiny = destinationsStatesSelected.value?.map((d) => ({
        //   code: destinationsCountry.value.code + ',' + d.code,
        //   label: destinationsCountry.value.label + ', ' + d.label,
        // })) ?? []
        quoteHotelsSearchRequest.destiny = {
          code:
            destinationsCountry.value.code + ',' + (destinationsStatesSelected.value?.code ?? ''),
          label:
            destinationsCountry.value.label +
            ', ' +
            (destinationsStatesSelected.value?.label ?? ''),
        };

        quoteHotelsSearchRequest.hotels_search_code = HotelsFormState.keywordFilter;
        quoteHotelsSearchRequest.price_range = HotelsFormState.priceRange;

        await getHotels(quoteHotelsSearchRequest);

        storeSidebar.setStatus(true, 'hotel', 'search');
      })
      .catch((error: string) => {
        console.log('error', error);
      });
  };
  const clearForm = () => {
    HotelsFormRef.value.resetFields();
  };
</script>

<template>
  <a-form
    id="hotels"
    class="container"
    ref="HotelsFormRef"
    :model="HotelsFormState"
    :rules="HotelFormRules"
  >
    <div class="row-box">
      <div class="input-box">
        <label for="start-date">{{ t('quote.label.check_in') }}: *</label>
        <a-form-item
          name="checkIn"
          :rules="{ required: true, message: t('quote.label.select_Check_in') }"
        >
          <a-date-picker
            v-model:value="HotelsFormState.checkIn"
            id="start-date"
            :format="dateFormat"
          />
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="end-date">{{ t('quote.label.check_out') }}: *</label>
        <a-form-item
          name="checkOut"
          :rules="{
            required: true,
            message: t('quote.label.select_Check_out'),
          }"
        >
          <a-date-picker
            v-model:value="HotelsFormState.checkOut"
            id="end-date"
            :format="dateFormat"
          />
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="country">{{ t('quote.label.destination') }}: *</label>
        <a-form-item
          name="destiny"
          :rules="{
            required: HotelsFormState.keywordFilter == '',
            message: t('quote.label.select_destination'),
          }"
        >
          <a-select
            showSearch
            optionFilterProp="label"
            v-model:value="HotelsFormState.destiny"
            :options="destinationsStates"
            :field-names="{ label: 'label', value: 'code' }"
            label-in-value
            @change="handleDestinationsStatesChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="country">{{ t('quote.label.category') }}: *</label>
        <a-form-item name="categories">
          <a-select
            v-model:value="HotelsFormState.categories"
            :options="categories"
            label-in-value
            @change="handleDestinationsCategoriesChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="slider-range">{{ t('quote.label.price_range') }}: *</label>
        <a-form-item name="priceRange">
          <a-slider
            v-model:value="HotelsFormState.priceRange.max"
            :marks="priceRange"
            :max="maxRange"
          >
            <template #mark="{ label, point }">
              <template v-if="point === 1000">
                <strong>{{ label }}</strong>
              </template>
              <template v-else>{{ label }}</template>
            </template>
          </a-slider>
        </a-form-item>
      </div>
    </div>
    <div class="row-box">
      <div class="input-box search">
        <label for="country">{{ t('quote.label.filter_by_words') }}: </label>
        <a-form-item name="keywordFilter" class="mb-0 w-100">
          <a-input v-model:value="HotelsFormState.keywordFilter" placeholder="Escribe aquí" />
        </a-form-item>
      </div>
      <div class="actions_buttons">
        <div class="text" @click="clearForm">
          <icon-clear />
          <span>{{ t('quote.label.clean_filters') }}</span>
        </div>
        <div class="search_button_container">
          <div class="search-button" @click="searchHotels">
            <div class="content">
              <div class="icon">
                <icon-search />
              </div>
              <div class="text">{{ t('quote.label.search') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>

<style lang="scss" scoped>
  form#hotels.container {
    gap: 12px !important;

    .input-box.search {
      .ant-row.ant-form-item {
        width: 26.5% !important;
      }
    }
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  @media only screen and (max-width: 1400px) {
    form#hotels.container {
      .input-box.search {
        .ant-row.ant-form-item {
          width: 28.5% !important;
        }
      }
    }
  }
</style>
