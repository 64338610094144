<template>
  <div class="box-merged">
    <a-row type="flex" align="start" justify="start" class="mb-2 mt-3">
      <a-col :span="props.type == 'cancellation' ? 24 : 11" v-if="props.type != 'new'">
        <div
          v-bind:class="[
            'box-left',
            props.flag_preview ? '' : 'box-bordered p-4',
            props.flag_simulation ? 'my-0' : '',
          ]"
        >
          <a-row
            align="middle"
            justify="end"
            class="mb-3"
            v-if="props.show_communication && emailsFrom.length > 0"
          >
            <span>Correo asociado a reservas:</span>
            <span class="mx-2 bordered">{{ emailsFrom[0] }}</span>
            <span class="cursor-pointer" v-on:click="showModalEmails('from', emailsFrom)">
              <i class="bi bi-plus-circle-dotted text-danger"></i>
            </span>
          </a-row>
          <div class="box-title" v-if="!props.flag_preview">
            <a-row type="flex" align="middle" justify="space-between">
              <a-col class="d-flex" style="gap: 7px">
                <big>
                  <i v-bind:class="[filesStore.showServiceIcon(props.from.object_code)]"></i>
                </big>
                <big class="ellipsis d-block">
                  <b>{{ props.from.name }}</b>
                </big>
                <a-tag color="#c63838">
                  {{ props.from.object_code }}
                </a-tag>
              </a-col>
            </a-row>
          </div>

          <div v-bind:class="['bg-pink-stick', props.flag_preview ? 'p-5' : 'p-3 mt-3 mb-4']">
            <a-row
              type="flex"
              align="middle"
              justify="space-between"
              class="mb-3"
              v-if="props.flag_preview"
            >
              <a-col class="d-flex" style="gap: 7px">
                <big>
                  <i v-bind:class="[filesStore.showServiceIcon(props.from.object_code)]"></i>
                </big>
                <big class="ellipsis d-flex"
                  ><b class="text-700">{{ props.from.name }}</b></big
                >
              </a-col>
              <a-col>
                <big class="me-1">
                  <i class="bi bi-calendar4"></i>
                </big>
                <big
                  ><b>{{ formatDate(from.date_in, 'DD/MM/YYYY') }}</b></big
                >
                <big><b class="mx-1" style="font-size: 16px">|</b></big>
                <big
                  ><b>{{ formatDate(from.date_out, 'DD/MM/YYYY') }}</b></big
                >
              </a-col>
            </a-row>
            <a-row
              type="flex"
              justify="space-between"
              :class="[props.flag_preview ? 'px-3 pt-2' : '']"
              align="middle"
            >
              <a-col v-if="!props.flag_preview">
                <CalendarOutlined />
                <b class="text-700">{{ formatDate(props.from.date_in, 'DD/MM/YYYY') }}</b>
              </a-col>
              <a-col>
                <b class="text-700">Pasajeros: </b>
                <i class="bi bi-person-fill"></i> {{ props.from.adults }}
                <i class="bi bi-person-arms-up"></i> {{ props.from.children }}
              </a-col>
              <a-col>
                <a
                  href="javascript:;"
                  v-on:click="showInformation(_service)"
                  class="text-danger text-underline"
                  >Más información del servicio</a
                >
              </a-col>
              <a-col v-if="props.type == 'cancellation'">
                <i class="bi bi-warning-triangle text-warning"></i>
                <b>Penalidad por cancelación:</b>
                <b class="h5 text-danger mb-0 mx-1"
                  >$ {{ formatNumber({ number: props.from.penality }) }}</b
                >
              </a-col>
              <a-col v-else>
                <i class="bi bi-warning-triangle text-warning"></i>
                <b>Costo:</b>
                <b class="h5 text-danger mb-0 mx-1"
                  >$ {{ formatNumber({ number: props.from.total_amount }) }}</b
                >
              </a-col>
            </a-row>
          </div>

          <template v-if="props.from.send_comunication">
            <a-row align="middle" type="flex" justify="space-between" class="mx-2">
              <a-col>
                <div
                  v-bind:class="[
                    'd-flex cursor-pointer',
                    !showNotesFrom ? 'text-dark-gray' : 'text-danger',
                  ]"
                  @click="showNotesFrom = !showNotesFrom"
                >
                  <template v-if="showNotesFrom">
                    <i
                      class="bi bi-check-square-fill text-danger d-flex"
                      style="font-size: 1.5rem"
                    ></i>
                  </template>
                  <template v-else>
                    <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                  </template>
                  <span class="mx-2">Agregar nota al proveedor</span>
                </div>
              </a-col>
            </a-row>
            <div class="mb-3 mx-2" v-if="showNotesFrom">
              <template v-if="lockedNotesFrom">
                <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                  <template #title>Nota en reserva para el proveedor:</template>
                  <template #extra>
                    <a href="javascript:;" @click="lockedNotesFrom = false" class="text-danger">
                      <i class="bi bi-pencil"></i>
                    </a>
                  </template>
                  <p class="mb-2">
                    <b>{{ notesFrom }}</b>
                  </p>
                  <template v-for="(file, f) in filesFrom" :key="f">
                    <a-row align="middle" class="mb-2">
                      <i class="bi bi-paperclip"></i>
                      <a :href="file" target="_blank" class="text-dark mx-1">
                        {{ showName(file) }}
                      </a>
                    </a-row>
                  </template>
                </a-card>
              </template>

              <template v-if="!lockedNotesFrom">
                <p class="text-danger my-2">Nota para el proveedor:</p>
                <a-row align="top" justify="space-between">
                  <a-col flex="auto">
                    <a-textarea
                      v-model:value="notesFrom"
                      placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                      :auto-size="{ minRows: 2 }"
                    />
                  </a-col>
                  <a-col class="mx-2">
                    <file-upload
                      v-bind:folder="'communications'"
                      @onResponseFiles="responseFilesFrom"
                    />
                  </a-col>
                  <a-col>
                    <a-button
                      danger
                      type="default"
                      size="large"
                      v-bind:disabled="!(notesFrom != '' || filesFrom.length > 0)"
                      class="d-flex ant-row-middle text-600"
                      @click="lockedNotesFrom = true"
                      :loading="communicationsStore.isLoading"
                    >
                      <i class="bi bi-floppy"></i>
                    </a-button>
                  </a-col>
                </a-row>
              </template>
            </div>
            <template v-if="lockedNotesFrom || !showNotesFrom">
              <a-row align="middle" type="flex" justify="end" class="mx-2">
                <a-col class="ant-row-end">
                  <a-button
                    danger
                    type="default"
                    class="d-flex ant-row-middle text-600"
                    @click="showCommunicationFrom()"
                    :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                  >
                    <i
                      class="bi bi-search"
                      v-if="!(communicationsStore.isLoading || communicationsStore.isLoadingAsync)"
                    ></i>
                    <span class="mx-2">Ver comunicación</span>
                  </a-button>
                </a-col>
              </a-row>
            </template>
          </template>
        </div>
      </a-col>
      <template v-if="props.to">
        <a-col
          :span="2"
          class="text-center merge-icon"
          v-if="props.type != 'new' && props.type != 'cancellation'"
        >
          <i
            class="bi bi-arrow-right-circle d-block"
            style="font-size: 4rem; padding-top: 6rem"
          ></i>
        </a-col>
        <a-col :span="props.type != 'modification' ? 24 : 11">
          <div
            v-bind:class="['box-left box-bordered p-4', props.flag_simulation ? 'my-0' : '']"
            v-for="(_service, s) in to"
            :key="s"
          >
            <a-row align="middle" justify="end" class="mb-3" v-if="_service.emails.length > 0">
              <span>Correo asociado a reservas:</span>
              <span class="mx-2 bordered">{{ _service.emails[0] }}</span>
              <span class="cursor-pointer" v-on:click="showModalEmails('to', emailsTo, s)">
                <i class="bi bi-plus-circle-dotted text-danger"></i>
              </span>
            </a-row>
            <div class="box-title" v-if="!props.flag_preview">
              <a-row type="flex" align="middle" justify="space-between">
                <a-col class="d-flex">
                  <span>
                    <i v-bind:class="[filesStore.showServiceIcon(_service.code)]"></i>
                  </span>
                  <span class="mx-2 ellipsis d-block">
                    <b>{{ _service.name }}</b>
                  </span>
                  <a-tag color="#c63838" class="mx-2">
                    {{ _service.code }}
                  </a-tag>
                </a-col>
              </a-row>
            </div>

            <div v-bind:class="['bg-purple-stick', props.flag_preview ? 'p-5' : 'p-3 mt-3 mb-4']">
              <a-row type="flex" align="middle" justify="space-between" v-if="props.flag_preview">
                <a-col class="d-flex">
                  <span>
                    <i v-bind:class="[filesStore.showServiceIcon(_service.code)]"></i>
                  </span>
                  <span class="mx-2 ellipsis d-block">
                    <b>{{ _service.name }}</b>
                  </span>
                  <a-tag color="#c63838" class="mx-2">
                    {{ _service.code }}
                  </a-tag>
                </a-col>
              </a-row>
              <a-row type="flex" justify="space-between" align="middle">
                <a-col>
                  <CalendarOutlined />
                  <b>{{ formatDate(_service.search_parameters_services.date, 'DD/MM/YYYY') }}</b>
                </a-col>
                <a-col>
                  <b>Pasajeros: </b>
                  {{ _service.quantity_adult }} <i class="bi bi-person-fill"></i>
                  {{ _service.quantity_child }} <i class="bi bi-person-arms-up"></i>
                </a-col>
                <a-col>
                  <a
                    href="javascript:;"
                    v-on:click="showInformation(_service)"
                    class="text-danger text-underline"
                    >Más información del servicio</a
                  >
                </a-col>
                <a-col>
                  <i class="bi bi-warning-triangle text-warning"></i>
                  <b>Costo:</b>
                  <b class="h5 text-danger mb-0 mx-1"
                    >$ {{ formatNumber({ number: _service.price }) }}</b
                  >
                </a-col>
              </a-row>
            </div>

            <template v-if="filesStore.getFileItinerary.send_comunication">
              <a-row align="middle" type="flex" justify="space-between" class="mx-2">
                <a-col>
                  <div
                    v-bind:class="[
                      'd-flex cursor-pointer',
                      !_service.showNotesTo ? 'text-dark-gray' : 'text-danger',
                    ]"
                    @click="_service.showNotesTo = !_service.showNotesTo"
                  >
                    <template v-if="_service.showNotesTo">
                      <i
                        class="bi bi-check-square-fill text-danger d-flex"
                        style="font-size: 1.5rem"
                      ></i>
                    </template>
                    <template v-else>
                      <i class="bi bi-square d-flex" style="font-size: 1.5rem"></i>
                    </template>
                    <span class="mx-2">Agregar nota al proveedor</span>
                  </div>
                </a-col>
              </a-row>
              <div class="mb-3 mx-2" v-if="_service.showNotesTo">
                <template v-if="_service.lockedNotesTo">
                  <a-card style="width: 100%" class="mt-3" :headStyle="{ background: black }">
                    <template #title> Nota en reserva para el proveedor:</template>
                    <template #extra>
                      <a
                        href="javascript:;"
                        @click="_service.lockedNotesTo = false"
                        class="text-danger"
                      >
                        <i class="bi bi-pencil"></i>
                      </a>
                    </template>
                    <p class="mb-2">
                      <b>{{ _service.notesTo }}</b>
                    </p>
                    <template v-for="(file, f) in _service.filesTo" :key="f">
                      <a-row align="middle" class="mb-2">
                        <i class="bi bi-paperclip"></i>
                        <a :href="file" target="_blank" class="text-dark mx-1">
                          {{ showName(file) }}
                        </a>
                      </a-row>
                    </template>
                  </a-card>
                </template>

                <template v-if="!_service.lockedNotesTo">
                  <p class="text-danger my-2">Nota para el proveedor:</p>
                  <a-row align="top" justify="space-between">
                    <a-col flex="auto">
                      <a-textarea
                        v-model:value="_service.notesTo"
                        placeholder="Escribe una nota para el proveedor que podrás visualizar en la comunicación"
                        :auto-size="{ minRows: 2 }"
                      />
                    </a-col>
                    <a-col class="mx-2">
                      <file-upload
                        v-bind:folder="'communications'"
                        @onResponseFiles="responseFilesTo($event, _service)"
                      />
                    </a-col>
                    <a-col>
                      <a-button
                        danger
                        type="default"
                        size="large"
                        v-bind:disabled="!(_service.notesTo != '' || _service.filesTo.length > 0)"
                        class="d-flex ant-row-middle text-600"
                        @click="_service.lockedNotesTo = true"
                        :loading="communicationsStore.isLoading"
                      >
                        <i class="bi bi-floppy"></i>
                      </a-button>
                    </a-col>
                  </a-row>
                </template>
              </div>
              <template v-if="_service.lockedNotesTo || !_service.showNotesTo">
                <a-row align="middle" type="flex" justify="end" class="mx-2">
                  <a-col class="ant-row-end">
                    <a-button
                      danger
                      type="default"
                      class="d-flex ant-row-middle text-600"
                      @click="showCommunicationTo(s)"
                      :loading="communicationsStore.isLoading || communicationsStore.isLoadingAsync"
                    >
                      <i
                        class="bi bi-search"
                        v-if="
                          !(communicationsStore.isLoading || communicationsStore.isLoadingAsync)
                        "
                      ></i>
                      <span class="mx-2">Ver comunicación</span>
                    </a-button>
                  </a-col>
                </a-row>
              </template>
            </template>
          </div>
        </a-col>
      </template>
    </a-row>
  </div>

  <div class="my-3" v-if="props.buttons">
    <a-row type="flex" justify="end" align="middle">
      <a-col>
        <a-button
          type="default"
          class="btn-default mx-2 px-4 text-600"
          v-on:click="prevStep()"
          default
          :disabled="filesStore.isLoading"
          size="large"
        >
          Cancelar
        </a-button>
        <a-button
          type="primary"
          class="mx-2 px-4 text-600"
          v-on:click="processReservation(false)"
          default
          :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
          size="large"
        >
          <template v-if="type == 'cancellation'">Continuar</template>
          <template v-else>Reservar</template>
        </a-button>
      </a-col>
    </a-row>
  </div>

  <a-modal
    v-model:open="modal.open"
    :title="modal.subject"
    :width="720"
    :closable="true"
    :maskClosable="false"
  >
    <template #footer>
      <a-row align="middle" justify="center">
        <a-col>
          <a-button
            key="button"
            type="primary"
            default
            size="large"
            class="text-600"
            @click="closeModal"
            >Cerrar
          </a-button>
        </a-col>
      </a-row>
    </template>
    <div v-html="modal.html"></div>
  </a-modal>

  <a-modal v-model:visible="modalInformation" :width="800">
    <template #title>
      <div class="text-left px-4 pt-4">
        <h6 class="mb-0">{{ service.name }}</h6>
        <a-tag
          color="#EB5757"
          style="
            position: absolute;
            top: 0px;
            right: 120px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding: 7px 15px;
            font-size: 18px;
            font-weight: 500;
          "
        >
          {{ service.service_type.name }}
        </a-tag>
      </div>
    </template>
    <div class="px-2">
      <a-row :gutter="24" type="flex" justify="space-between" align="top">
        <a-col :span="15">
          <p class="text-700">Operatividad</p>
          <p class="mb-0">Sistema horario de 24 horas</p>
          <p>
            {{ service.operations.turns[0].departure_time }}
            {{ service.operations.turns[0].shifts_available }}
          </p>
        </a-col>
        <a-col :span="9">
          <template v-if="service.inclusions.length > 0">
            <p>
              <b>Incluye</b>
            </p>
            <p>
              <template v-for="inclusion in service.inclusions">
                <a-tag v-for="item in inclusion.include">{{ item.name }}</a-tag>
              </template>
            </p>
          </template>
          <p>
            <b>Disponibilidad</b>
          </p>
          <a-row type="flex" justify="space-between" align="top" style="gap: 5px">
            <a-col>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 11.2049V12.1249C21.9988 14.2813 21.3005 16.3795 20.0093 18.1067C18.7182 19.8338 16.9033 21.0973 14.8354 21.7088C12.7674 22.3202 10.5573 22.2468 8.53447 21.4994C6.51168 20.7521 4.78465 19.371 3.61096 17.5619C2.43727 15.7529 1.87979 13.6129 2.02168 11.4612C2.16356 9.30943 2.99721 7.26119 4.39828 5.62194C5.79935 3.98268 7.69279 2.84025 9.79619 2.36501C11.8996 1.88977 14.1003 2.1072 16.07 2.98486"
                  stroke="#1ED790"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 4.125L12 14.135L9 11.135"
                  stroke="#1ED790"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a-col>
            <a-col>
              <p class="mb-1">Dias:</p>
              <template v-if="Object.values(service.operations.days).length == 7">
                Todos los días
              </template>
              <template v-else>
                <p class="m-0" v-for="(day, d) in service.operations.days">
                  {{ d }}
                </p>
              </template>
            </a-col>
            <a-col>
              <p class="mb-1">Horario</p>
              <template v-if="Object.values(service.operations.days).length == 7">
                <p class="text-danger text-400 mb-0">
                  {{ service.operations.schedule[0]['friday'] }}.
                </p>
              </template>
              <template v-else>
                <p class="m-0" v-for="(day, d) in service.operations.schedule">{{ d }}.</p>
              </template>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <template #footer></template>
  </a-modal>

  <a-modal v-model:open="flagModalEmails" :width="720" :closable="true" :maskClosable="false">
    <template #title>
      <span class="text-center">Correos adicionales para solicitud de reserva:</span>
    </template>
    <a-form layout="vertical">
      <a-form-item label="Agregar correos adicionales">
        <a-select
          v-model:value="emails"
          mode="tags"
          style="width: 100%"
          placeholder="Agregar correos adicionales"
          :options="[]"
        ></a-select>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-row align="middle" justify="center">
        <a-col>
          <a-button
            key="button"
            type="default"
            default
            size="large"
            class="text-600"
            @click="closeModalEmails"
            >Cancelar</a-button
          >
          <a-button
            key="button"
            type="primary"
            default
            size="large"
            class="text-600"
            @click="handleChangeEmails"
            >Guardar</a-button
          >
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { formatDate, formatNumber } from '@/utils/files.js';
  import { CalendarOutlined } from '@ant-design/icons-vue';
  import { useFilesStore } from '@store/files';
  import { useCommunicationsStore } from '@/stores/global';
  import FileUpload from '@/components/global/FileUploadComponent.vue';
  import Cookies from 'js-cookie';

  const emit = defineEmits(['onPrevStep', 'onNextStep', 'onLoadReservation']);

  const params_cancellation = ref({});

  const props = defineProps({
    buttons: {
      type: Boolean,
      default: () => true,
    },
    show_communication: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: Boolean,
      default: () => true,
    },
    from: {
      type: Object,
      default: () => {},
    },
    to: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Array,
      default: () => [],
    },
    date_from: {
      type: String,
      default: () => '',
    },
    date_to: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    filter: {
      type: Object,
      default: () => {},
    },
    flag_simulation: {
      type: Boolean,
      default: () => false,
    },
    flag_preview: {
      type: Boolean,
      default: () => false,
    },
  });

  onBeforeMount(async () => {
    console.log('PROPS SERVICE: ', props);

    client_id.value = localStorage.getItem('client_id');

    if (client_id.value == '' || client_id.value == null) {
      client_id.value = 15766;
    }

    if (props.type == 'new') {
      type_to.value = 'new';
    }

    if (props.type == 'modification') {
      if (props.from.object_code != props.to[0].code) {
        // Es son hoteles diferentes.. update
        type_from.value = 'cancellation';
        type_to.value = 'new';
      } else {
        type_from.value = 'modification';
        type_to.value = '';
      }
    }

    if (props.type == 'cancellation') {
      type_from.value = 'cancellation';
      type_to.value = '';
    }

    // Generar el param query para la cancelación..
    let services_cancellation = [];

    if (props.from != undefined) {
      props.from.services.forEach((service) => {
        let compositions = [];

        service.compositions.forEach((composition) => {
          compositions.push(composition.id);
        });

        let item = {
          id: service.id,
          compositions: compositions,
        };

        services_cancellation.push(item);
      });
    }

    if (props.from != undefined && props.from.object_code != undefined) {
      await searchProvidersFrom(props.from.object_code);
    }

    params_cancellation.value = services_cancellation;

    if (props.to != undefined) {
      props.to.forEach(async (_provider) => {
        await searchProvidersTo(_provider.code);
      });
    }
  });

  const filesStore = useFilesStore();
  const communicationsStore = useCommunicationsStore();

  // const filter = ref({});
  const type_from = ref('');
  const type_to = ref('');

  const emailsFrom = ref([]);
  const emailsTo = ref([]);

  const showNotesFrom = ref(false);
  const lockedNotesFrom = ref(false);

  const notesFrom = ref('');
  const filesFrom = ref([]);

  const client_id = ref('');

  const modal = ref({
    open: false,
    html: '',
    subject: '',
  });

  const showCommunicationFrom = async () => {
    console.log('FROM: ', props.from);

    let params = {
      services: params_cancellation.value,
      notas: notesFrom.value,
      attachments: filesFrom.value,
    };

    await communicationsStore.previewCommunication(
      'itineraries/' + props.from.id,
      params,
      'service',
      type_to.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
  };

  const showCommunicationTo = async (_index) => {
    console.log('TO: ', props.to);
    let equivalence = props.to[_index];
    console.log(equivalence);

    /*
    await communicationsStore.previewCommunication(
      'itineraries/' + props.from.id,
      params,
      'service',
      type_to.value
    );

    modal.value.html = communicationsStore.getHtml;
    modal.value.subject = communicationsStore.getSubject;
    modal.value.open = true;
    */
  };

  const searchProvidersFrom = async (_object_code) => {
    await filesStore.fetchProviders(_object_code);
    emailsFrom.value =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const searchProvidersTo = async (_object_code) => {
    await filesStore.fetchProviders(_object_code);
    emailsTo.value =
      typeof filesStore.getProvider.emails != 'undefined' ? filesStore.getProvider.emails : [];
  };

  const closeModal = () => {
    modal.value.open = false;
  };

  const responseFilesFrom = (files) => {
    filesFrom.value = files.map((item) => item.link);
  };

  const responseFilesTo = (files, service) => {
    service.filesTo = files.map((item) => item.link);
  };

  const showName = (file) => {
    let parts = file.split('/').splice(-1);
    return parts[0];
  };

  const emails = ref([]);
  const emailsType = ref('');
  const emailsService = ref(0);

  const showModalEmails = (type, _emails, service) => {
    emails.value = _emails;
    emailsType.value = type;
    emailsService.value = service;
    flagModalEmails.value = true;
  };

  const closeModalEmails = () => {
    flagModalEmails.value = false;
  };

  const processReservation = async (flag_return) => {
    let cancellation = {};
    let response = {};

    if (props.type != 'new') {
      cancellation = {
        type: 'service',
        flag_email: 'cancellation',
        itinerary_id: props.from.id,
        services: params_cancellation.value,
        notas: notesFrom.value,
        attachments: filesFrom.value,
      };

      response = {
        reservation: props,
        params: cancellation,
      };

      if (typeof flag_return == 'undefined' || !flag_return) {
        emit('onLoadReservation', response);
      }
    }

    if (props.type != 'cancellation') {
      for (const service of props.to) {
        let _passengers = [];
        let quantity_adults = 0;
        let quantity_child = 0;

        if (typeof service.passengers == undefined) {
          for (const passenger of filesStore.getFilePassengers) {
            if (
              filter.value.quantity_persons_rooms[0].adults > quantity_adults &&
              passenger.type == 'ADL'
            ) {
              _passengers.push(passenger);
              quantity_adults++;
            }

            if (
              filter.value.quantity_persons_rooms[0].child > quantity_child &&
              passenger.type == 'CHD'
            ) {
              _passengers.push(passenger);
              quantity_child++;
            }
          }
        } else {
          _passengers = service.passengers;
        }

        let reservation = {
          client_id: localStorage.getItem('client_id'),
          executive_id: Cookies.get(window.USER_ID),
          file_code: filesStore.getFile.fileNumber,
          reference: '',
          guests: filesStore.getFilePassengers,
          reservations: [],
          reservations_services: [service],
          entity: 'Cart',
          object_id: null,
        };

        let params = {
          type: 'service',
          flag_email: 'new',
          reservation_add: reservation,
          notas: service.notesTo,
          attachments: service.filesTo,
          file_id: filesStore.getFile.id,
        };

        response = {
          reservation: props,
          params: params,
        };

        if (typeof flag_return == 'undefined' || !flag_return) {
          emit('onLoadReservation', response);
        }
      }
    }

    if (flag_return) {
      return response;
    }
  };

  const prevStep = () => {
    emit('onPrevStep');
  };

  const service = ref({});
  const modalInformation = ref(false);

  const showInformation = async (_service) => {
    await filesStore.findServiceInformation(
      _service.object_id,
      _service.date_in,
      _service.adults + _service.children
    );
    service.value = filesStore.getServiceInformation;

    setTimeout(() => {
      modalInformation.value = true;
    }, 100);
  };

  const handleChangeEmails = () => {
    if (emailsType.value == 'from') {
      emailsFrom.value = emails.value;
    }

    if (emailsType.value == 'to') {
      props.to[emailsService.value].emails = emails.value;
    }

    flagModalEmails.value = false;
  };

  defineExpose({
    processReservation,
  });
</script>
