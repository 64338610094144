<script lang="ts" setup>
  interface Props {
    color?: string;
    width?: number;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    color: '#3D3D3D',
    width: 30,
    height: 30,
  });
</script>

<template>
  <svg
    :width="props.width"
    :height="props.height"
    :viewBox="`0 0 ${props.width} ${props.height}`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.67111 5.1309L5.29558 5.48411L5.66013 5.84866L7.36557 7.5541C7.37195 7.56048 7.37448 7.56484 7.37543 7.56668C7.37645 7.56865 7.37699 7.57034 7.37731 7.5722C7.37804 7.57644 7.37801 7.58481 7.37374 7.59513C7.36946 7.60545 7.36357 7.61139 7.36005 7.61387C7.35851 7.61495 7.35694 7.61577 7.35483 7.61644C7.35286 7.61707 7.348 7.61836 7.33898 7.61836H2.45414C2.43334 7.61836 2.4165 7.60152 2.4165 7.58072V2.69588C2.4165 2.68686 2.41779 2.682 2.41842 2.68003C2.41909 2.67792 2.41991 2.67635 2.42099 2.6748C2.42347 2.67129 2.42942 2.66539 2.43974 2.66112C2.45006 2.65684 2.45844 2.65681 2.46268 2.65754C2.46454 2.65786 2.46623 2.6584 2.4682 2.65942C2.47004 2.66037 2.47439 2.66289 2.48076 2.66926C2.48076 2.66927 2.48077 2.66927 2.48078 2.66928L4.13976 4.32826L4.48539 4.67389L4.83877 4.33619C6.24554 2.99178 8.15081 2.16675 10.2498 2.16675C14.5711 2.16675 18.0752 5.66585 18.0832 9.98524C18.0911 14.3003 14.5705 17.8303 10.2555 17.8334C8.39099 17.8347 6.6796 17.1852 5.33395 16.0992L5.01993 16.4882L5.33395 16.0992C5.19463 15.9867 5.18205 15.775 5.31169 15.6453L5.69028 15.2667C5.80419 15.1528 5.98071 15.1455 6.09758 15.2382C7.23755 16.1428 8.68093 16.6829 10.2498 16.6829C13.9435 16.6829 16.9326 13.6932 16.9326 10.0001C16.9326 6.3064 13.9429 3.31728 10.2498 3.31728C8.4774 3.31728 6.86639 4.00664 5.67111 5.1309ZM12.4395 11.9003L12.4395 11.9003L12.1095 12.3247C12.1095 12.3247 12.1095 12.3247 12.1094 12.3247C12.0055 12.4582 11.813 12.4823 11.6794 12.3784C11.6794 12.3784 11.6794 12.3784 11.6794 12.3784L9.67456 10.8191V6.50545C9.67456 6.3362 9.81176 6.199 9.98101 6.199H10.5186C10.6879 6.199 10.8251 6.3362 10.8251 6.50545V10.0118V10.2563L11.0181 10.4065L12.3858 11.4702L12.3858 11.4702C12.5194 11.5741 12.5435 11.7666 12.4395 11.9003Z"
      :fill="props.color"
      :stroke="props.color"
    />
  </svg>
</template>

<style lang="scss"></style>
