<script lang="ts" setup>
  import type { UnwrapRef } from 'vue';
  import { computed, reactive, ref } from 'vue';
  import dayjs, { Dayjs } from 'dayjs';

  import { useSiderBarStore } from '../../store/sidebar';
  import { useQuote } from '@/quotes/composables/useQuote';
  import IconClear from '@/quotes/components/icons/IconClear.vue';
  import IconSearch from '@/quotes/components/icons/IconSearch.vue';

  import type { DestinationsState, QuoteHotelCategory } from '@/quotes/interfaces';
  import { useQuoteServices } from '@/quotes/composables/useQuoteServices';
  import type { ServicesType } from '@/quotes/interfaces/services';
  import type { Rule } from 'ant-design-vue/es/form';
  import useQuoteTranslations from '@/quotes/composables/useQuoteTranslations';
  import { useQuoteHotelCategories } from '@/quotes/composables/useQuoteHotelCategories';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  // store
  // TODO: refactor to use composable instead
  const storeSidebar = useSiderBarStore();
  const { getLang } = useQuoteTranslations();
  // Composable
  const { quote, deleteServiceSelected, selectedCategory } = useQuote();
  const { servicesTypes, getCountryStates, getExtensionsAvailable } = useQuoteServices();

  // categories
  const { quoteHotelCategories: categories } = useQuoteHotelCategories();

  const destinationsStates = computed(() => getCountryStates('89'));
  const servicesTypesFiltered = computed(() => servicesTypes.value.filter((t) => t.code !== 'NA'));

  const date_from = computed(() => {
    const categories = quote.value.categories.find((c) => {
      return c.type_class_id === selectedCategory.value;
    });

    let date_in = [];
    categories.services.forEach((element) => {
      if (element.type == 'group_extension') {
        element.extensions.forEach((element2) => {
          date_in.push(new Date(element2.service.date_in_format));
        });
      } else {
        date_in.push(new Date(element.service.date_in_format));
      }
    });

    let maxDate = new Date(Math.max.apply(null, date_in));

    console.log(maxDate, date_in);
    return maxDate.setDate(maxDate.getDate() + 1);
  });

  // Form
  const searchFormRef = ref();

  interface SearchForm {
    date_from: Dayjs | undefined;
    state: DestinationsState | null;
    service_type: ServicesType | null;
    service_name: string;
    categories: QuoteHotelCategory | undefined;
  }

  const searchFormState: UnwrapRef<SearchForm> = reactive({
    date_from: dayjs(date_from.value),
    state: null,
    service_type: {
      abbreviation: 'SIM',
      code: 'SIM',
      id: 1,
      label: t('quote.label.shared'),
      translations: [],
    },
    service_name: '',
    categories: undefined,
  });

  const searchFormRules: Record<string, Rule[]> = {
    date_from: [
      {
        required: true,
        message: t('quote.label.select_start_day'),
        trigger: 'change',
        type: 'object',
      },
    ],
    type_service: [
      {
        required: true,
        message: t('quote.label.select_type_service'),
        trigger: 'change',
      },
    ],
  };

  const dateFormat = 'DD/MM/YYYY';

  // const contentScroll = ref('content');

  const stateChange = (value: { option: DestinationsState }) => {
    searchFormState.state = value.option;
  };

  const categoryChange = (value: { option: ServicesType }) => {
    searchFormState.service_type = value.option;
  };

  const search = () => {
    storeSidebar.setStatus(false, '', '');
    deleteServiceSelected();
    searchFormRef.value
      .validate()
      .then(async () => {
        await getExtensionsAvailable({
          date: searchFormState.date_from!.format('YYYY-MM-DD'),
          type_service: searchFormState.service_type?.id ?? '',
          lang: getLang(),
          filter: searchFormState.service_name,
          destination: searchFormState.state ? searchFormState.state.label : '',
          type_class_id: searchFormState.categories ? searchFormState.categories.value : '',
        });

        storeSidebar.setStatus(true, 'extension', 'search');
      })
      .catch((error: string) => {
        console.log('error', error);
      });
  };
  const clearForm = () => {
    searchFormRef.value.resetFields();
  };

  const handleDestinationsCategoriesChange = (value: { option: QuoteHotelCategory }) => {
    searchFormState.categories = value.option;
  };
</script>

<template>
  <a-form
    id="tours"
    class="container"
    ref="searchFormRef"
    :model="searchFormState"
    :rules="searchFormRules"
  >
    <div class="row-box">
      <div class="input-box">
        <label for="date_from">{{ t('quote.label.day_start') }}: *</label>
        <a-form-item name="date_from">
          <a-date-picker
            v-model:value="searchFormState.date_from"
            id="start-date"
            :format="dateFormat"
          />
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="state">{{ t('quote.label.city') }}: </label>
        <a-form-item name="state">
          <a-select
            showSearch
            optionFilterProp="label"
            v-model:value="searchFormState.state"
            :options="destinationsStates"
            :field-names="{ label: 'label', value: 'code' }"
            label-in-value
            @change="stateChange"
            :allow-clear="true"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="service_type">{{ t('quote.label.type_services') }}: </label>
        <a-form-item name="service_type">
          <a-select
            v-model:value="searchFormState.service_type"
            :options="servicesTypesFiltered"
            :field-names="{ label: 'label', value: 'id' }"
            label-in-value
            :allow-clear="true"
            @change="categoryChange"
          ></a-select>
        </a-form-item>
      </div>
      <div class="input-box">
        <label for="country">{{ t('quote.label.category') }}: *</label>
        <a-form-item name="categories">
          <a-select
            v-model:value="searchFormState.categories"
            :options="categories"
            label-in-value
            @change="handleDestinationsCategoriesChange"
            :allow-clear="true"
          ></a-select>
        </a-form-item>
      </div>
    </div>
    <div class="row-box">
      <div class="input-box search">
        <label for="service_name">{{ t('quote.label.filter_by_words') }}: </label>
        <a-form-item name="service_name" class="mb-0 w-100">
          <a-input
            v-model:value="searchFormState.service_name"
            :placeholder="t('quote.label.write_here')"
          />
        </a-form-item>
      </div>
      <div class="actions_buttons">
        <div class="text" @click="clearForm">
          <icon-clear />
          <span>{{ t('quote.label.clean_filters') }}</span>
        </div>
        <div class="search_button_container" @click="search">
          <div class="search-button">
            <div class="content">
              <div class="icon">
                <icon-search />
              </div>
              <div class="text">{{ t('quote.label.search') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-form>
</template>

<style lang="scss" scoped>
  .input-box {
    &.duration {
      flex-grow: 0 !important;
      /* flex-shrink: 0 !important; */
      flex-basis: 19.2% !important;
    }
  }

  form#tours.container {
    .input-box.search {
      .ant-row.ant-form-item {
        width: 33.5% !important;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    form#tours.container {
      .input-box.search {
        .ant-row.ant-form-item {
          width: 35.5% !important;
        }
      }
    }
  }
</style>
