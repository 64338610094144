// import auth from '@/middleware/auth'; //TODO: remove this comment
// import checkPermission from '@/middleware/CheckPermission';
// import OperatingView from '../views/operations/OperatingView.vue';
import DashboardLayout from '@views/backend/BackendDashboardLayout.vue';

const ROUTE_NAME = 'ope';

export default {
  path: `/${ROUTE_NAME}`,
  name: ROUTE_NAME,
  component: DashboardLayout,
  // beforeEnter: checkPermission,
  meta: {
    breadcrumb: 'Operaciones',
    // middleware: auth, //TODO: remove this comment
    // permission: 'mffilesquery',
    // action: 'read',
    // breadcrumb: 'Home',
  },
  redirect: `/${ROUTE_NAME}/dashboard`,
  children: [
    {
      path: `lockdown-calendar`,
      name: 'lockdown-calendar',
      component: () => import('@/modules/operations/block-calendar/pages/BlockCalendarPage.vue'),
      meta: {
        breadcrumb: 'Calendario de Bloqueos',
      },
    },
    {
      path: `guidelines`,
      name: 'guidelines',
      component: () =>
        import('@/modules/operations/operational-guidelines/pages/OperatingGuidelines.vue'),
      meta: {
        breadcrumb: 'Pautas operativas',
      },
    },
    {
      path: `service-scheduling`,
      name: 'service-scheduling',
      component: () => import('@/modules/operations/service-scheduling/pages/PrincipalPage.vue'),
      meta: {
        breadcrumb: 'Programación de servicios',
      },
    },
  ],
};
